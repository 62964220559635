<template>
<div>
  <SettingOne></SettingOne>
</div>
</template>

<script>
import SettingOne from "@/components/setting/SettingOne.vue";

export default {
  name: "SettingOneView",
  components: {
    SettingOne
  }
}
</script>

<style scoped>

</style>