<template>
  <div>
    <v-row class="mx-2">
      <v-col class="font-weight-bold blue--text">SETTING APLIKASI</v-col>
    </v-row>
    <v-row justify="center" class="mx-4">
      <v-expansion-panels inset>
        <v-expansion-panel>
          <v-expansion-panel-header class="orange--text">Fonnte Whatsapp Notifikasi dan Token</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="itemModified.tokenFonnte"
                    label="Token Whatsapp Fonnte" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                    v-model="itemModified.tokenFonntePublic"
                    :items="itemsStatusPublic"
                    item-text="status"
                    item-value="status"
                    Label="Penggunaan Public atau Private Token"
                    dense
                    outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
              <v-col cols="12" md="12">
                <v-text-field
                    v-model="itemModified.urlValidasiFonnte"
                    label="URL Utama untuk Aktifkasi Akun" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="align-baseline">
              <v-col cols="12" md="12">
                <v-text-field
                    v-model="itemModified.msgValidasiFonnte1"
                    label="Pesan Whatsapp untuk Permintaan Validasi Akun" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                    v-model="itemModified.msgValidasiFonnte2"
                    label="Pesan penutup" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="itemModified.waBackOffice1"
                    label="WhatsApp Back Office" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn @click="testSendMsg" class="ml-2" color="primary" small>Tes Send<v-icon class="ml-1" small>mdi-send</v-icon></v-btn>
              </v-col>
            </v-row>

          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>SIASN</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="itemModified.siasnUserId"
                    label="USER ID" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    v-model="itemModified.siasnPassword"
                    label="PASSWORD" outlined dense></v-text-field>
              </v-col>
            </v-row>


          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row>
      <v-col class="mx-4">
        <v-btn color="primary" @click="saveSetting" :disabled="!isItemModified">Simpan</v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import FSysvarService from "@/services/apiservices/f-sysvar-service";
import ApiFonnteService from "@/services/apiservices/api-fonnte-service";

export default {
  name: "SettingOne",
  components: {
  },
  data() {
    return {
      itemModified:{
        tokenFonnte: '',
        tokenFonntePublic: '',
        urlValidasiFonnte: '',
        msgValidasiFonnte1: '',
        msgValidasiFonnte2: '',
        waBackOffice1: '',

        siasnUserId: '',
        siasnPassword: '',
      },
      itemDefault: undefined,

      itemsStatusPublic: [
        { status: 'Public' },
        { status: 'Private'},
      ],
      itemsFSysvarCompany: []
    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },
  },
  methods: {
    saveSetting() {
      const tokenFonnteItem = this.itemsFSysvarCompany.filter(x => x.kode1 === 'FN_TOKEN')[0]
      const urlValidasiFonnteItem = this.itemsFSysvarCompany.filter(x => x.kode1 === 'FN_VAL_URL')[0]
      const msgValidasiFonnteItem = this.itemsFSysvarCompany.filter(x => x.kode1 === 'FN_VAL_ACC_MSG')[0]

      const waBackOffice1 = this.itemsFSysvarCompany.filter(x => x.kode1 === 'FN_WA_ADM_1')[0]
      const siasnUserId = this.itemsFSysvarCompany.filter(x => x.kode1 === 'SIAS_API_USRID')[0]
      const siasnPassword = this.itemsFSysvarCompany.filter(x => x.kode1 === 'SIAS_API_PASSWD')[0]

      tokenFonnteItem.nilaiString1 = this.itemModified.tokenFonnte
      tokenFonnteItem.nilaiString2 = this.itemModified.tokenFonntePublic
      // console.log(JSON.stringify(tokenFonnteItem.nilaiString2))

      urlValidasiFonnteItem.nilaiString1 = this.itemModified.urlValidasiFonnte

      msgValidasiFonnteItem.nilaiString1 = this.itemModified.msgValidasiFonnte1
      msgValidasiFonnteItem.nilaiString2 = this.itemModified.msgValidasiFonnte2

      waBackOffice1.nilaiString1 = this.itemModified.waBackOffice1

      siasnUserId.nilaiString1 = this.itemModified.siasnUserId
      siasnPassword.nilaiString1 = this.itemModified.siasnPassword

      const listFSysvarToSave = []
      listFSysvarToSave.push(tokenFonnteItem)
      listFSysvarToSave.push(urlValidasiFonnteItem)
      listFSysvarToSave.push(msgValidasiFonnteItem)
      listFSysvarToSave.push(waBackOffice1)
      listFSysvarToSave.push(siasnUserId)
      listFSysvarToSave.push(siasnPassword)



      FSysvarService.updateAllFSysvarCompany(listFSysvarToSave).then(
          () => {
            this.fetchData()
          }
      )

    },

    fetchData() {
      this.itemsFSysvarCompany = []
      FSysvarService.getAllFSysvarCompany().then(
          response => {
            // console.log(JSON.stringify(response.data))

            this.itemsFSysvarCompany = response.data
            // console.log(JSON.stringify(this.itemsFSysvarCompany))

            if (this.itemsFSysvarCompany.length > 0){
              const item = {
                tokenFonnte: this.itemsFSysvarCompany.filter(x => x.kode1 === 'FN_TOKEN')[0].nilaiString1,
                tokenFonntePublic: this.itemsFSysvarCompany.filter(x => x.kode1 === 'FN_TOKEN')[0].nilaiString2,
                urlValidasiFonnte: this.itemsFSysvarCompany.filter(x => x.kode1 === 'FN_VAL_URL')[0].nilaiString1,
                msgValidasiFonnte1: this.itemsFSysvarCompany.filter(x => x.kode1 === 'FN_VAL_ACC_MSG')[0].nilaiString1,
                msgValidasiFonnte2: this.itemsFSysvarCompany.filter(x => x.kode1 === 'FN_VAL_ACC_MSG')[0].nilaiString2,
                waBackOffice1: this.itemsFSysvarCompany.filter(x => x.kode1 === 'FN_WA_ADM_1')[0].nilaiString1,

                siasnUserId: this.itemsFSysvarCompany.filter(x => x.kode1 === 'SIAS_API_USRID')[0].nilaiString1,
                siasnPassword: this.itemsFSysvarCompany.filter(x => x.kode1 === 'SIAS_API_PASSWD')[0].nilaiString1,

              }

              this.itemDefault = Object.assign({}, item);
              this.itemModified = item;
            }

          }
      )
    },

    testSendMsg() {
      // console.log('test send msg')
      const simpleMessage = {
        to: this.itemModified.waBackOffice1,
        subject: "Verifikator Silayak",
        message: 'Test Send Message, dari Setting Aplikasi',
      };
      ApiFonnteService.postFonnteSendMessagePublic(simpleMessage).then(
          response => {
            console.log(JSON.stringify(response.data))
          },
          error => {
            console.log(JSON.stringify(error))
          }
      );

    }
  },
  mounted() {
    this.fetchData()
  }
}

</script>


<style scoped>

</style>